<template>
  <v-dialog
    v-model="isShowModal"
    max-width="640px">
    <v-card ref="card">
      <material-card
        :title="$t('infoVoucher.voucherInformation')"
        tile
        color="green"
        full-width
      >
        <v-form>
          <v-layout wrap>
            <v-flex
              xs12
              sm6
              md6>
              <div class="mb-2">
                <img
                  :src="voucherDetail.imageUrl !== null && voucherDetail.imageUrl !== '' ? voucherDetail.imageUrl : e_noImage"
                  width="300"
                  height="200"
                  style="border: 1px solid lightgray;">
              </div>
              <div style="display: flex; flex-direction: column;">
                <span class="text-bold text-title">{{ voucherDetail.name }}</span>
                <span>{{ $t('evoucher.issuance.supplier') }}: <span class="text-bold">{{ voucherDetail.supplierName }}</span></span>
                <span v-if="!voucherDetail.isHidePrice">{{ $t('evoucher.issuance.price') }}: <span class="text-bold">{{ convertToMoney(voucherDetail.value) }} {{ voucherDetail.discountType == VoucherValue.PRICE ? 'VND' : '%' }}</span></span>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              md6
              text-xs-right>
              <div class="mb-2">
                <qrcode-vue
                  :size="150"
                  :value="voucherDetail.serial"
                  level="H"
                  render-as="svg" />
              </div>
              <div style="display: flex; flex-direction: column">
                <span>S/N: <span class="text-bold">{{ voucherDetail.serial }}</span></span>
                <span>{{ $t('evoucher.issuance.status') }}: <span class="text-bold">{{ $t(getVoucherStatusType(voucherDetail.status)) }}</span></span>
                <span>{{ $t('supplierAccessCode.expiredDate') }}: <span class="text-bold">{{ voucherDetail.expiredDate ? voucherDetail.expiredDate : 'N/A' }}</span></span>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm12
              md12>
              <div class="divider-text bg-light" />
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  md12>
                  <tab-header
                    :tab-items="tabVoucherItems"
                    :selected-id="tabsVoucherSelected"
                    @onSelectTab="onSelectTab" />
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md12
                  class="px-1 mt-2">
                  <div v-if="tabsVoucherSelected==tabVoucherInfo.DESCRIPTION">
                    <span class="pre-text">{{ voucherDetail.description }}</span>
                  </div>
                  <div v-if="tabsVoucherSelected==tabVoucherInfo.TERM">
                    <span class="pre-text">{{ voucherDetail.conditionsOfUse }}</span>
                  </div>
                  <div v-if="tabsVoucherSelected==tabVoucherInfo.EXTRA_INFO">
                    <span class="pre-text">{{ voucherDetail.details }}</span>
                  </div>
                  <div v-if="tabsVoucherSelected==tabVoucherInfo.ADDRESS_USAGE">
                    <div
                      v-for="(address, index) in voucherDetail.usageSites"
                      :key="address + index"
                      class="font-medium mt-2 ml-3">
                      <span class="d-block text-bold">{{ index + 1 + '. ' + address.name }}</span>
                      <div
                        v-if="address.fullAddress"
                        class="d-inline-flex">
                        <address-label
                          :longitude="address.latitude"
                          :latitude="address.longitude">
                          {{ address.fullAddress }}
                        </address-label>
                        <br>
                      </div>
                      <div
                        v-if="address.email"
                        class="d-inline-flex">
                        <v-icon
                          class="ml-2 mr-1"
                          size="16">mdi-email</v-icon>
                        <span><a :href="`mailto:${address.email}`">{{ address.email }}</a></span>
                        <br>
                      </div>
                      <div
                        v-if="address.phone"
                        class="d-inline-flex">
                        <v-icon
                          class="ml-2 mr-1"
                          size="16">mdi-phone</v-icon>
                        <span><a :href="`tel:${address.phone}`">{{ address.phone }}</a></span>
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          style="margin-right: 0;"
          color="red"
          @click="onCloseModal()">
          <div style="color: #fff;">
            <span class="btn-label mr-2">
              <i class="glyphicon glyphicon-remove" />
            </span>
            <span style="text-transform: none;">{{
              $t("common.close")
            }}</span>
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
// import moment from 'moment'
import VoucherValue from 'enum/voucherValue'
import { FOR_CONASI_TARGET } from 'utils/constants'
import QrcodeVue from 'qrcode.vue'
import TabHeader from 'Components/TabHeader.vue'
import AddressLabel from 'Components/AddressLabel.vue'

const TabVoucherInfo = {
  DESCRIPTION: 1,
  TERM: 2,
  ADDRESS_USAGE: 3,
  EXTRA_INFO: 4
}

export default {
  components: {
    QrcodeVue,
    AddressLabel,
    TabHeader
  },
  data: () => ({
    isShowModal: false,
    e_noImage: require('@/assets/noimage.png'),
    voucherDetail: {
      id: null,
      serial: '',
      type: null,
      value: null,
      name: null,
      description: null,
      conditionsOfUse: null,
      details: null,
      imageUrl: null,
      supplierName: null,
      status: null,
      expiredDate: null,
      // usedPrice: null,
      // usedDiscountPrice: null,
      workpieceCode: null,
      usageSites: [],
      discountType: null,
      isPersistentCode: false,
      isHidePrice: false
    },
    VoucherValue: VoucherValue,
    tabVoucherItems: [
      {
        title: 'evoucher.issuance.voucherDescription',
        id: TabVoucherInfo.DESCRIPTION
      },
      {
        title: 'product.detail.conditionsOfUse',
        id: TabVoucherInfo.TERM
      },
      {
        title: 'evoucher.detail.usageSite',
        id: TabVoucherInfo.ADDRESS_USAGE
      }
    ],
    tabVoucherInfo: TabVoucherInfo,
    tabsVoucherSelected: TabVoucherInfo.DESCRIPTION,
    indexRetrievingAddessUsage: 0,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_DETAIL_DATA'
    ])
  },
  watch: {
    VOUCHER_DETAIL_DATA () {
      let data = this.VOUCHER_DETAIL_DATA
      this.voucherDetail.id = data.id
      this.voucherDetail.serial = data.serial
      this.voucherDetail.expiredDate = dateUtils.formatBeautyDate(data.expired_date, null)
      this.voucherDetail.name = data.workpiece.name
      this.voucherDetail.description = data.description
      this.voucherDetail.supplierName = data.owner_data.name
      this.voucherDetail.type = data.type
      this.voucherDetail.value = data.value
      this.voucherDetail.status = data.status
      this.voucherDetail.discountType = data.workpiece.type
      // this.voucherDetail.usedPrice = data.used_price
      // this.voucherDetail.usedDiscountPrice = data.used_discount_price
      this.voucherDetail.workpieceCode = data.workpiece ? data.workpiece.code : null
      // this.voucherDetail.isTransferable = data.workpiece ? data.workpiece.is_transferable : null
      this.voucherDetail.imageUrl = data.workpiece ? data.workpiece.image_url : null
      this.voucherDetail.conditionsOfUse = data.workpiece ? data.workpiece.conditions_of_use : null
      this.voucherDetail.details = data.workpiece ? data.workpiece.details : null
      this.voucherDetail.usageSites = []
      data.usage_sites.forEach((site) => {
        let item = {
          id: site.id,
          name: site.name,
          code: site.code,
          longitude: site.longitude,
          latitude: site.latitude,
          province: site.province,
          district: site.district,
          ward: site.ward,
          street: site.street,
          fullAddress: site.street
        }
        this.voucherDetail.usageSites.push(item)
      })
      this.voucherDetail.isPersistentCode = data.workpiece.is_persistent_code
      this.voucherDetail.isHidePrice = data.workpiece.is_hide_price

      this.indexRetrievingAddessUsage = 0
      this.doRetrievingFullAddressUsage()
    }
  },
  created () {
    if (this.forConasi) {
      this.tabVoucherItems.push({
        title: 'evoucher.detail.details',
        id: TabVoucherInfo.EXTRA_INFO
      })
    }
  },
  methods: {
    onSelectTab: function ({ index, item }) {
      this.tabsVoucherSelected = item.id
    },
    /**
     * Get e voucher status type
     */
    getVoucherStatusType: function (status) {
      return functionUtils.getVoucherStatusText(status)
    },
    /**
     * Convert to money
     */
    convertToMoney: function (value) {
      return functionUtils.convertFormatNumberToMoney(value)
    },
    /**
     * Close
     */
    onCloseModal: function () {
      // window.history.back()
      this.isShowModal = false
    },
    /**
     * Get e voucher detail
     */
    getEVoucherDetail: function (voucherId) {
      this.GET_VOUCHER_DETAIL({ id: voucherId })
    },
    doRetrievingFullAddressUsage: function () {
      if (this.voucherDetail && this.voucherDetail.usageSites && this.indexRetrievingAddessUsage < this.voucherDetail.usageSites.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.voucherDetail.usageSites[this.indexRetrievingAddessUsage].province,
            districtCode: this.voucherDetail.usageSites[this.indexRetrievingAddessUsage].district,
            wardCode: this.voucherDetail.usageSites[this.indexRetrievingAddessUsage].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.voucherDetail.usageSites[this.indexRetrievingAddessUsage].street)
            this.voucherDetail.usageSites[this.indexRetrievingAddessUsage].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddessUsage++
            this.doRetrievingFullAddressUsage()
          }.bind(this)
        )
      }
    },
    opCopyClipboard: function (text) {
      navigator.clipboard.writeText(text)
    },
    onShowModal: function (voucherId, voucherInfo) {
      this.isShowModal = true
      this.tabsVoucherSelected = TabVoucherInfo.DESCRIPTION
      if (voucherId !== null) {
        this.getEVoucherDetail(voucherId)
      } else if (voucherInfo !== null) {
        this.voucherDetail.id = voucherInfo.id
        this.voucherDetail.serial = voucherInfo.serial ? voucherInfo.serial : ''
        this.voucherDetail.type = voucherInfo.type
        this.voucherDetail.value = voucherInfo.value
        this.voucherDetail.name = voucherInfo.name
        this.voucherDetail.description = voucherInfo.description
        this.voucherDetail.conditionsOfUse = voucherInfo.conditionsOfUse
        this.voucherDetail.details = voucherInfo.details
        this.voucherDetail.imageUrl = voucherInfo.imageUrl
        this.voucherDetail.supplierName = voucherInfo.supplierName
        this.voucherDetail.status = voucherInfo.status
        this.voucherDetail.expiredDate = voucherInfo.expiredDate
        // this.voucherDetail.usedPrice = voucherInfo.usedPrice
        // this.voucherDetail.usedDiscountPrice = voucherInfo.usedDiscountPrice
        this.voucherDetail.workpieceCode = voucherInfo.workpieceCode
        this.voucherDetail.usageSites = voucherInfo.usageSites
        this.voucherDetail.discountType = voucherInfo.discountType

        this.indexRetrievingAddessUsage = 0
        this.doRetrievingFullAddressUsage()
      }
    },
    ...mapActions([
      'GET_VOUCHER_DETAIL',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.text-title {
  font-size: 18px !important;
  color: green;
}
.icon-button:hover {
  color: rgb(0, 0, 145);
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 19.5px solid transparent;
  border-bottom: 19.5px solid transparent;
  border-left: 20px solid red;
  position: absolute;
  top: 0;
  right: -20px;
}
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.pre-text {
  white-space: pre-wrap !important;
}
.divider-text {
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 9px;
}
.divider-text:after {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #ddd;
  top: 55%;
  left: 0;
  z-index: 1;
}
</style>
