<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="80%">
    <v-card>
      <material-card
        :title="$t('voucherReconciliation.detail')"
        tile
        color="green"
        full-width
      >
        <v-layout wrap>
          <v-flex
            xs12
            md12
            class="pr-1"
          >
            <v-layout wrap>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.siteName"
                  :label="$t('voucherReconciliation.detailInfo.fromSite')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.userCreatedName"
                  :label="$t('voucherReconciliation.detailInfo.userSummitted')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.submitDate"
                  :label="$t('voucherReconciliation.detailInfo.dateSummitted')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            md12
            class="pl-1"
          >
            <v-layout wrap>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.supplierName"
                  :label="$t('voucherReconciliation.detailInfo.toSupplier')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.userApprovalName"
                  :label="$t('voucherReconciliation.detailInfo.userApproval')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
              <v-flex
                xs4
                md4
              >
                <v-text-field
                  v-model="reconciliation_info.approvalDate"
                  :label="$t('voucherReconciliation.detailInfo.dateApproval')"
                  class="mx-1"
                  readonly
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <template>
          <v-btn
            v-if="selected.length > 0 && isSupplier() && !fromHistory"
            color="success"
            dark
            @click="performCheck(eActionType.APPROVED, selected)"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            <span style="text-transform: none;">{{
              $t("voucherReconciliation.approveSelectedVoucher")
            }}</span>
          </v-btn>
          <v-btn
            v-if="selected.length > 0 && isSupplier() && !fromHistory"
            color="error"
            dark
            @click="performCheck(eActionType.REJECTED, selected)"
          >
            <v-icon class="mr-2">mdi-close</v-icon>
            <span style="text-transform: none;">{{
              $t("voucherReconciliation.cancelSelectedVoucher")
            }}</span>
          </v-btn>
        </template>
        <div @mouseleave="$refs.tblReconciliationItemList.expanded=[]">
          <v-data-table
            ref="tblReconciliationItemList"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherInfoHeaders"
            :items="voucherInfoList"
            :loading="true"
            v-model="selected"
            :select-all="(isSupplier() && !isProcessedReconciliation()) ? true : false"
            :expand="false"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <tr
                :class="props.selected ? 'blue--text' : ''"
                @click="props.expanded=!props.expanded"
                @mouseover="props.expanded=true">
                <td v-if="(isSupplier() && !isProcessedReconciliation())">
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details />
                </td>
                <td
                  v-for="(header, index) in voucherInfoHeaders"
                  :key="header.value + index">
                  <span v-if="header.value == 'voucherSerial'">
                    {{ props.item[header.value] }}
                  </span>
                  <span v-else-if="header.value == 'price'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span v-else-if="header.value == 'usedPrice'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span
                    v-else-if="header.value == 'state'"
                    :style="`color: ${getColor(props.item[header.value])};`">
                    {{ $t(getStatus(props.item[header.value])) }}
                  </span>
                  <div
                    v-else-if="header.value == 'actions'"
                    class="d-flex">
                    <!-- Aprrove reconciliation -->
                    <v-tooltip
                      v-if="!isProcessedReconciliation()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="props.item.state == VoucherReconciliationStatus.APPROVED"
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="performCheck(eActionType.APPROVED, [props.item.id])"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("voucherReconciliation.action.approve") }}</span>
                    </v-tooltip>
                    <!-- Reject Reconciliation -->
                    <v-tooltip
                      v-if="!isProcessedReconciliation()"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="props.item.state == VoucherReconciliationStatus.REJECTED"
                          class="mx-2"
                          fab
                          small
                          color="error"
                          v-on="on"
                          @click="performCheck(eActionType.REJECTED, [props.item.id])"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("voucherReconciliation.action.cancel") }}</span>
                    </v-tooltip>
                  </div>
                  <span v-else>{{ props.item[header.value] }}</span>
                </td>
              </tr>
            </template>
            <template v-slot:expand="props">
              <div
                class="py-2 mx-3"
                style="display: flex; white-space: nowrap; padding-left: 50px"
                @mouseleave="props.expanded=false">
                <action-item
                  first
                  class="text-success"
                  @click="showPreviewVoucher($event, props.item.voucherId)">
                  {{ $t('voucherReconciliation.action.view') }}
                </action-item>
                <!-- <action-item
                  class="text-success"
                  @click="viewDetailEVoucher($event, props.item.voucherId)">
                  {{ $t('voucherReconciliation.action.view') }}
                </action-item> -->
                <action-item
                  class="text-red"
                  @click="viewHistoryTransaction($event, props.item.voucherId)">
                  {{ $t('voucherReconciliation.viewHistory') }}
                </action-item>
              </div>
            </template>
          </v-data-table>
        </div>
        <pagination
          :total="eVoucherPaginate.totalPage"
          :current-page="eVoucherPaginate.currentPage"
          :row-per-page="eVoucherPaginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="warning darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="!fromHistory && isUsageSite() && reconciliationType == VoucherReconciliationType.NOT_SUBMIT_YET"
          :loading="isSendCheckLoading"
          color="success darken-1"
          @click="onExecuteReconciliationVoucher(eActionType.WAIT_FOR_APPROVAL)"
        >{{ $t("voucherReconciliation.action.submit") }}
        </v-btn>
        <v-btn
          v-if="!fromHistory && isSupplier() && reconciliationType == VoucherReconciliationType.WAIT_FOR_REVIEW"
          :loading="isSendCheckLoading"
          color="error darken-1"
          @click="onExecuteReconciliationVoucher(eActionType.REJECTED)"
        >{{ $t("voucherReconciliation.action.cancel") }}
        </v-btn>
        <v-btn
          v-if="!fromHistory && isSupplier() && reconciliationType == VoucherReconciliationType.WAIT_FOR_REVIEW"
          :loading="isSendCheckLoading"
          color="primary darken-1"
          @click="onExecuteReconciliationVoucher(eActionType.APPROVED)"
        >{{ $t("voucherReconciliation.action.approve") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirm-modal
      ref="confirmSubmitModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmVoucherReconciliation"
    />
    <confirm-with-text-modal
      ref="confirmVoucherReconciliationModal"
      :title="confirmModalTitle"
      :input-title="$t('voucherReconciliation.comment')"
      :input-lines="2"
      prepend-icon="mdi-tooltip-edit"
      @onConfirm="onConfirmVoucherReconciliation"
    />
    <confirm-with-text-modal
      ref="confirmReconciliationItemModal"
      :title="confirmModalTitle"
      :input-title="$t('voucherReconciliation.comment')"
      :input-lines="2"
      prepend-icon="mdi-tooltip-edit"
      @onConfirm="onConfirmReconciliation"
    />
    <historyTransactionVoucher ref="historyTransactionVoucher" />
    <e-voucher-preview-modal ref="voucherPreviewModal" />
  </v-dialog>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import VoucherReconciliationStatus from 'enum/voucherReconciliationStatus'
import VoucherReconciliationType from 'enum/voucherReconciliationType'
import EntityType from 'enum/entityType'
import ConfirmModal from 'Components/ConfirmModal'
import ConfirmWithTextModal from 'Components/ConfirmWithTextModal'
import HistoryTransactionVoucher from '../../HistoryTransactionVoucher.vue'
import EVoucherPreviewModal from 'Views/EVoucher/EVoucherPreviewModal'
import ActionItem from 'Components/ActionItem.vue'
export default {
  components: {
    ConfirmModal,
    ConfirmWithTextModal,
    HistoryTransactionVoucher,
    // EVoucherDetailModal,
    EVoucherPreviewModal,
    ActionItem
  },
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherUsed.orderCode', value: 'orderCode' },
      { text: 'voucherUsed.voucherSerial', value: 'voucherSerial' },
      { text: 'voucherUsed.voucherName', value: 'name' },
      { text: 'voucherUsed.price', value: 'price' },
      { text: 'entity.supplier', value: 'supplier' },
      { text: 'voucherUsed.customerUsed', value: 'usedTime' },
      { text: 'voucherUsed.priceUsed', value: 'usedPrice' },
      { text: 'voucherUsed.reconciliation', value: 'state' },
      { text: 'voucherReconciliation.comment', value: 'comment' },
      { text: 'common.actions', value: 'actions' }
    ],
    voucherInfoList: [],
    reconciliation_info: {
      // id: null,
      // code: null,
      // createdDate: null,
      submitDate: null,
      approvalDate: null,
      // totalVoucher: null,
      status: null,
      // siteId: null,
      siteName: null,
      // supplierId: null,
      supplierName: null,
      // userCreatedId: null,
      userCreatedName: null,
      // userApprovalId: null,
      userApprovalName: null
      // totalPrice: null,
      // totalVoucherReconciled: null,
      // totalVoucherWaitForApproval: null,
      // totalVoucherCanceled: null
    },
    isShowModal: false,
    reconciliationId: null,
    supplierId: null,
    isSendCheckLoading: false,
    reconciliationType: null,
    VoucherReconciliationStatus: VoucherReconciliationStatus,
    VoucherReconciliationType: VoucherReconciliationType,
    actionType: null,
    confirmModalTitle: '',
    fromHistory: false,
    eVoucherPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    eActionType: {
      WAIT_FOR_APPROVAL: 1,
      APPROVED: 2,
      REJECTED: 3
    },
    voucherUsageIds: [],
    selected: []
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    /**
     * View history transaction
     */
    viewHistoryTransaction: function (event, voucherId) {
      event.preventDefault()
      this.$refs.historyTransactionVoucher.onShowModal(voucherId)
    },
    showPreviewVoucher: function (event, voucherId) {
      event.preventDefault()
      this.$refs.voucherPreviewModal.onShowModal(voucherId)
    },
    /**
     * Perform check
     */
    performCheck: function (type, ids) {
      this.actionType = type
      let usageIds = []
      let isObject = false
      for (let i = 0, size = ids.length; i < size; i++) {
        if (typeof ids[i] === 'object') {
          usageIds.push(ids[i].id)
          isObject = true
        }
      }
      if (isObject) {
        this.voucherUsageIds = usageIds
      } else {
        this.voucherUsageIds = ids
      }

      if (type === this.eActionType.REJECTED) {
        this.confirmModalTitle = 'Bạn có chắc chắn muốn từ chối đối soát cho voucher được chọn?'
      } else if (type === this.eActionType.APPROVED) {
        this.confirmModalTitle = 'Bạn có chắc chắn muốn xác nhận đối soát cho các voucher được chọn?'
      }
      this.$refs.confirmReconciliationItemModal.onShowModal()
    },
    onExecuteReconciliationVoucher: function (type) {
      this.actionType = type
      if (type === this.eActionType.WAIT_FOR_APPROVAL) {
        this.confirmModalTitle = 'Bạn có chắc chắn muốn gửi đối soát tới nhà cung cấp?'
        this.$refs.confirmSubmitModal.onShowModal()
      } else {
        if (type === this.eActionType.REJECTED) {
          this.confirmModalTitle = 'Bạn có chắc chắn muốn từ chối đối soát này? Chú ý: tất cả các voucher trong đối soát sẽ chuyển sang trạng thái hủy đối soát'
        } else if (type === this.eActionType.APPROVED) {
          this.confirmModalTitle = 'Bạn có chắc chắn muốn xác nhận đối soát cho các voucher trong danh sách?'
        }
        this.$refs.confirmVoucherReconciliationModal.onShowModal()
      }
    },
    /**
     * Get color
     */
    getColor: function (status) {
      if (VoucherReconciliationStatus.NOT_REQUEST_YET === status || functionUtils.isNull(status)) {
        return 'blue'
      } else if (VoucherReconciliationStatus.WAIT_FOR_APPROVAL === status) {
        return 'green'
      } else if (VoucherReconciliationStatus.APPROVED === status) {
        return 'green'
      } else {
        return 'red'
      }
    },
    /**
     * Get status text
     */
    getStatus: function (status) {
      if (functionUtils.isNull(status)) {
        if (this.reconciliationType === VoucherReconciliationType.APPROVED) {
          return 'voucherReconciliation.status.reconciled'
        } else if (this.reconciliationType === VoucherReconciliationType.REJECTED) {
          return 'voucherReconciliation.status.canceled'
        } else {
          return 'voucherReconciliation.status.waitForApproval'
        }
      }
      return functionUtils.getReconciliationStatusText(status)
    },
    /**
     * Confirm action
     */
    onConfirmReconciliation: function (comment) {
      if (this.actionType === this.eActionType.REJECTED || this.actionType === this.eActionType.APPROVED) {
        this.onProcessApprovalItem(comment)
      }
    },
    onConfirmVoucherReconciliation: function (comment) {
      if (this.actionType === this.eActionType.WAIT_FOR_APPROVAL) {
        this.submitReconciliationRequest()
      } else if (this.actionType === this.eActionType.REJECTED || this.actionType === this.eActionType.APPROVED) {
        this.processApprovalVoucherReconciliation(comment)
      }
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    isProcessedReconciliation: function () {
      return this.reconciliationType === VoucherReconciliationType.APPROVED || this.reconciliationType === VoucherReconciliationType.REJECTED
    },
    onProcessApprovalItem: function (comment) {
      let filter = {
        modeAction: this.actionType,
        reconciliationId: this.reconciliationId,
        voucherUsageIds: this.voucherUsageIds,
        comment: comment
      }
      this.PROCESS_APPROVAL_RECONCILIATION_ITEM(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getVouchersReconciliationDetail()
        }.bind(this)
      ).catch(
        function (error) {
          var errorText = this.$t('login.somethingWentWrong')
          if (!functionUtils.isNull(error.response) && !functionUtils.isNull(error.response.data.errorText)) {
            errorText = error.response.data.errorText
          }
          this.ON_SHOW_TOAST({
            message: this.$t(`${errorText}`),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    processApprovalVoucherReconciliation: function (comment) {
      let filter = {
        modeAction: this.actionType,
        reconciliationId: this.reconciliationId,
        comment: comment
      }
      this.PROCESS_APPROVAL_VOUCHER_RECONCILIATION(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.isShowModal = false
          this.$emit('sendCheckSuccess')
        }.bind(this)
      ).catch(
        function (error) {
          var errorText = this.$t('login.somethingWentWrong')
          if (!functionUtils.isNull(error.response) && !functionUtils.isNull(error.response.data.errorText)) {
            errorText = error.response.data.errorText
          }
          this.ON_SHOW_TOAST({
            message: this.$t(`${errorText}`),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Send check voucher
     */
    submitReconciliationRequest: function () {
      let filter = {
        reconciliationId: this.reconciliationId,
        supplierId: this.supplierId
      }
      this.isSendCheckLoading = true
      this.SUBMIT_RECONCILIATION_REQUEST(filter).then(
        function () {
          this.isSendCheckLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.isShowModal = false
          this.$emit('sendCheckSuccess')
        }.bind(this)
      )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.eVoucherPaginate.currentPage = page
      this.getVouchersReconciliationDetail()
    },
    /**
     * Get vouchers check detail
     */
    resetVouchersReconciliationSummary: function () {
      // this.reconciliation_info.id = null
      // this.reconciliation_info.code = null
      // this.reconciliation_info.createdDate = null
      this.reconciliation_info.submitDate = null
      this.reconciliation_info.approvalDate = null
      // this.reconciliation_info.totalVoucher = null
      this.reconciliation_info.status = null
      // this.reconciliation_info.siteId = null
      this.reconciliation_info.siteName = null
      // this.reconciliation_info.supplierId = null
      this.reconciliation_info.supplierName = null
      // this.reconciliation_info.userCreatedId = null
      this.reconciliation_info.userCreatedName = null
      // this.reconciliation_info.userApprovalId = null
      this.reconciliation_info.userApprovalName = null
      // this.reconciliation_info.totalPrice = null
      // this.reconciliation_info.totalVoucherReconciled = null
      // this.reconciliation_info.totalVoucherWaitForApproval = null
      // this.reconciliation_info.totalVoucherCanceled = null
    },
    getVouchersReconciliationSummary: function () {
      let filter = {
        params: {
          reconciliationId: this.reconciliationId
        }
      }
      this.GET_VOUCHERS_RECONCILIATION_SUMMARY(filter).then(
        function (res) {
          // this.reconciliation_info.id = res.data.id
          // this.reconciliation_info.code = res.data.code
          // this.reconciliation_info.createdDate = res.data.date_created
          this.reconciliation_info.submitDate = dateUtils.formatBeautyDate(res.data.submit_date)
          this.reconciliation_info.approvalDate = dateUtils.formatBeautyDate(res.data.approval_date)
          // this.reconciliation_info.totalVoucher = res.data.total_voucher
          this.reconciliation_info.status = res.data.status
          // this.reconciliation_info.siteId = res.data.usage_site_id
          this.reconciliation_info.siteName = res.data.site_name
          // this.reconciliation_info.supplierId = res.data.supplier_id
          this.reconciliation_info.supplierName = res.data.supplier_name
          // this.reconciliation_info.userCreatedId = res.data.user_created_id
          this.reconciliation_info.userCreatedName = functionUtils.concatFullname(res.data.user_created_firstname, res.data.user_created_lastname)
          // this.reconciliation_info.userApprovalId = res.data.user_approval_id
          this.reconciliation_info.userApprovalName = functionUtils.concatFullname(res.data.user_approval_firstname, res.data.user_approval_lastname)
          // this.reconciliation_info.totalPrice = res.data.total_price
          // this.reconciliation_info.totalVoucherReconciled = res.data.total_voucher_reconciled
          // this.reconciliation_info.totalVoucherWaitForApproval = res.data.total_voucher_wait_for_approval
          // this.reconciliation_info.totalVoucherCanceled = res.data.total_voucher_canceled
        }.bind(this)
      )
    },
    getVouchersReconciliationDetail: function () {
      let filter = {
        params: {
          page: this.eVoucherPaginate.currentPage,
          reconciliationId: this.reconciliationId
        }
      }
      this.GET_VOUCHERS_RECONCILIATION_DETAIL(filter).then(
        function (res) {
          this.eVoucherPaginate.currentPage = res.data.page
          this.eVoucherPaginate.totalPage = res.data.num_pages
          this.eVoucherPaginate.rowPerPage = res.data.per_page / 2
          this.voucherInfoList = []
          let data = res.data.results
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              stt: i + 1 + (res.data.page - 1) * res.data.page_size,
              id: data[i].usage_info.id,
              orderCode: data[i].usage_info.code,
              voucherSerial: data[i].voucher_info.serial,
              name: data[i].voucher_info.name,
              price: data[i].voucher_info.price,
              supplier: data[i].voucher_info.supplier,
              usedTime: dateUtils.formatBeautyDate(data[i].usage_info.used_date),
              usedSite: data[i].usage_info.used_site,
              usedPrice: data[i].usage_info.price,
              reconciliationStatus: data[i].usage_info.reconciliation_status,
              state: data[i].state,
              comment: data[i].comment,
              voucherId: data[i].voucher_info.id
            }
            this.voucherInfoList.push(obj)
          }
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function (item, fromHistory) {
      if (this.isUsageSite() || fromHistory) {
        this.voucherInfoHeaders = this.voucherInfoHeaders.filter((element) => {
          return element.value !== 'actions'
        })
      }
      this.fromHistory = fromHistory
      this.isShowModal = true
      this.supplierId = item.supplierId
      this.reconciliationId = item.id
      this.reconciliationType = item.status
      this.resetVouchersReconciliationSummary()
      this.getVouchersReconciliationSummary()
      this.getVouchersReconciliationDetail()
    },
    ...mapActions([
      'SUBMIT_RECONCILIATION_REQUEST',
      'ON_SHOW_TOAST',
      'PROCESS_APPROVAL_RECONCILIATION_ITEM',
      'PROCESS_APPROVAL_VOUCHER_RECONCILIATION',
      'GET_VOUCHERS_RECONCILIATION_DETAIL',
      'GET_VOUCHERS_RECONCILIATION_SUMMARY'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
